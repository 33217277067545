import React from "react";
import { withTheme } from "styled-components";
import copy from "copy-text-to-clipboard";
import Swal from "sweetalert2";
import { Box } from "../components/shared/Box";
import { Link } from "../components/shared/Link";
import { Typography } from "../components/shared/Typography";
import { config } from "../config";

interface LandingProps {
  theme: any;
}

const Landing = ({ theme }: LandingProps) => {
  const handleCopyDiscord = () => {
    if (copy(config.discord)) {
      Swal.fire({
        title: "Success!",
        text: "My discord tag has been copied to your clipboard!",
        icon: "success",
        confirmButtonText: "Ok",
      });
    } else {
      Swal.fire({
        title: "Failed!",
        text: "Could not copy my discord tag to your clipboard!",
        icon: "error",
        confirmButtonText: "Ok",
      });
    }
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      margin="30px auto"
      paddingX="30px"
      style={{ gap: "30px" }}
    >
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box>
          <Box>
            <Typography fontSize="25px">Julian O&apos;Shaughnessy</Typography>
          </Box>
          <Box>
            <Typography fontSize="20px" color={theme.colors.subtext}>
              Technical Lead in Toronto
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box
        display="flex"
        style={{
          gap: "30px",
        }}
      >
        <Box>
          <Link
            color={theme.colors.blue}
            target="_blank"
            href={config.links.twitter}
          >
            twitter
          </Link>
        </Box>
        <Box
          onClick={handleCopyDiscord}
          color={theme.colors.yellow}
          style={{
            cursor: "pointer",
            textDecoration: "underline",
          }}
        >
          <Typography color={theme.colors.yellow}>discord</Typography>
        </Box>
        <Box>
          <Link
            color={theme.colors.pink}
            target="_blank"
            href={config.links.github}
          >
            github
          </Link>
        </Box>
        <Box>
          <Link
            color={theme.colors.green}
            target="_blank"
            href={config.links.wallet}
          >
            wallet
          </Link>
        </Box>
      </Box>
      <Box maxWidth="700px">
        <Typography>
          Hi 👋,
          {
            // eslint-disable-next-line
            " I'm "
          }
          {
            // eslint-disable-next-line
            "Julian! I'm an experienced digital entrepreneur with almost 7 years of experience "
          }
          in the gaming and e-commerce industry.
        </Typography>
      </Box>
      <Box>
        <Box>
          <Typography fontSize="20px">[+] Updates</Typography>
        </Box>
        <Box marginTop="10px">
          <Typography>
            I don&apos;t share much on social media, but post occasionally on{" "}
            <Link
              target="_blank"
              href={config.links.twitter}
              color={theme.colors.yellow}
            >
              Twitter
            </Link>
            .
          </Typography>
        </Box>
      </Box>
      <Box>
        <Box>
          <Typography fontSize="20px">[-] Changelog</Typography>
        </Box>
        <Box marginTop="10px">
          <Typography>
            {
              // eslint-disable-next-line
              "Life moves quick. 🏃 "
            }
          </Typography>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          marginY="30px"
          style={{
            gap: "30px",
          }}
        >
          <Box>
            <Typography>
              {
                // eslint-disable-next-line
                "Interested in what I'm working on? Check out my "
              }
              <Link
                target="_blank"
                href={config.links.linkedin}
                color={theme.colors.yellow}
              >
                LinkedIn
              </Link>
              .
            </Typography>
          </Box>
          <Box marginTop="10px">
            <Typography>
              Powered by web3 and{" "}
              <Link
                target="_blank"
                href={config.links.ipfs}
                color={theme.colors.yellow}
              >
                IPFS
              </Link>
              !
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default withTheme(Landing);
